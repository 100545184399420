import React, { useState, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import { navigate } from "@reach/router";
import SearchBar from "../../common/CustomTable/common/SearchBar";
import { CustomCheckBox } from "../../common/FormInputs";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Chip, CircularProgress, makeStyles, Tooltip } from "@material-ui/core";
import PartyService from "../../../services/PartyService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import CustomTable from "./MaterialTable";
import { Cancel } from "@material-ui/icons";
import { Header } from "../Header/Header";
import labels from "../../../helpers/labels.json";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "25px",
    display: "flex",
    justifyContent: "staet",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  chipLabel: {
    fontSize: "14px",
    fontFamily: "openSans-SemiBold, sans-serif",
    color: "#293461",
  },
  deleteIcon: {
    color: "#d91b1b", // Change delete icon color
    "&:hover": {
      color: "#d91b1b", // Change color on hover
    },
  },
  customTooltip: {
    color: "#ffffff",
    fontSize: "12px",
    borderRadius: "8px",
    fontFamily: "openSans-SemiBold, sans-serif",
  },
}));

const BulkUploadReports = () => {
  const classes = useStyles();
  const [partyList, setPartyList] = useState([]);
  const [selectPartyList, setSelectedPartyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    async function getDocumentsReport(stringParams) {
      try {
        setLoading(true);
        const response = await PartyService.getDocumentsReport(stringParams);
        if (response) {
          setCurrentData(response);
        }
      } catch (err) {
        enqueueSnackbar(err?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }

    if (selectPartyList?.length) {
      let stringParams = `?partyIds=${selectPartyList
        .map((item) => item.id)
        .join(",")
        .replace(/\s+/g, "")}`;
      getDocumentsReport(stringParams);
    } else {
      let stringParams = `?partyIds=`;
      getDocumentsReport(stringParams);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPartyList?.length]);

  useEffect(() => {
    async function getParties(params) {
      try {
        setLoader({ state: true });
        const response = await PartyService.index(
          "?perPage=4000&status=active",
        );
        if (response?.data?.length) {
          setPartyList(response.data);
        }
      } catch (error) {
        enqueueSnackbar(error?.message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    getParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "dashboard":
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  const filteredData = partyList.filter((item) =>
    item.name.toLowerCase().includes(inputText.toLowerCase()),
  );

  /*custom columns */

  const columns = [
    {
      field: "caseId",
      title: "Case ID",
      render: (rowData) => <Center>{rowData?.caseId}</Center>,
      width: 110,
    },
    {
      field: "title",
      title: "Title",
      sorting: true,
      render: (rowData) => (
        <Tooltip
          title={rowData?.title}
          placement="top"
          arrow
          classes={{
            tooltip: classes.customTooltip,
          }}
        >
          <Center width={rowData?.title?.length > 40 ? "250px" : "auto"}>
            {rowData?.title}
          </Center>
        </Tooltip>
      ),
      width: 280,
    },
    {
      field: "contractNumber",
      title: "Contract number",
      render: (rowData) => (
        <Center>
          {rowData?.contractNumber ? rowData?.contractNumber : "-"}
        </Center>
      ),
      width: 140,
    },

    {
      field: "document_count",
      title: "Document count",
      sorting: true,
      render: (rowData) => <Center>{rowData?.document_count}</Center>,
      width: 140,
    },
    {
      field: "documentNames",
      title: "Document names",
      sorting: true,
      render: (rowData) => (
        <Tooltip
          title={rowData?.documentNames}
          placement="top"
          arrow
          classes={{
            tooltip: classes.customTooltip,
          }}
        >
          <Center
            width={rowData?.documentNames?.length > 30 ? "210px" : "auto"}
          >
            {rowData?.documentNames}
          </Center>
        </Tooltip>
      ),
      width: 250,
    },
  ];

  let excelData =
    currentData?.length &&
    currentData?.map((item) => ({
      CaseId: item?.caseId,
      Title: item?.title,
      CaseDescription: item?.description,
      ContractNumber: item?.contractNumber,
      "Document Count": item?.document_count,
      "Document Names": item?.documentNames,
    }));

  return (
    <Header
      showSidebar
      selectedItem={labels.my_tasks}
      selectedSubItem={labels.document_reports}
    >
      <ActionBar
        breadcrumbs={["Dashboard", "Bulk upload reports"]}
        {...{ onBreadcrumbClick }}
      />
      <Container>
        <GirdContainer>
          <InputBox>
            <SearchBar
              name={"partysearch"}
              placeHolderText="Search Parties"
              backgroundColor="#ffffff"
              {...{ inputText, setInputText }}
              width="100%"
            />

            <div className="party-listing">
              <>
                {inputText &&
                  filteredData.map((party, index) => (
                    <CheckboxContainer key={index}>
                      <CustomCheckBox
                        checked={selectPartyList.some(
                          (el) => el.id === party.id,
                        )}
                        name={party?.name + index}
                        onChange={(ev) =>
                          setSelectedPartyList((prev) => {
                            // Check if the viewer with the same ID already exists
                            const exists = prev.some(
                              (obj) => obj.id === party.id,
                            );

                            if (exists) {
                              // Remove the viewer by filtering it out
                              return prev.filter((obj) => obj.id !== party.id);
                            } else {
                              // Add the new viewer
                              return [...prev, party];
                            }
                          })
                        }
                      />
                      <span className="checkboxlabel">
                        {party.name} {`(Party Id: ${party.id})`}
                      </span>
                    </CheckboxContainer>
                  ))}
                {inputText && partyList.length === 0 ? (
                  <div className="no-record">No Parties found</div>
                ) : (
                  <div className="no-record"></div>
                )}
              </>
            </div>
          </InputBox>
          <ChipContainer className={classes.root}>
            {selectPartyList?.map((party, index) => (
              <Chip
                variant="outlined"
                classes={{ label: classes.chipLabel }}
                size="small"
                label={party.name}
                onDelete={() => {
                  setSelectedPartyList(
                    [...selectPartyList].filter((el) => el.id !== party.id),
                  );
                }}
                deleteIcon={<Cancel className={classes.deleteIcon} />}
              />
            ))}
          </ChipContainer>
        </GirdContainer>

        {!loading ? (
          <CustomTable
            hidePagination={currentData?.lastPage === 1}
            pageSize={10}
            pluralTitle={"Reports"}
            singularTitle={"Reports"}
            placeholderText={"Search"}
            noToolbar={true}
            {...{
              columns,
              page,
              setPage,
              excelData,
            }}
            // detailPanel={detailPanel}
            // loading={loading}
            data={currentData}
            state={currentData}
          />
        ) : (
          <Loader>
            <CircularProgress />
          </Loader>
        )}
      </Container>
    </Header>
  );
};

export default BulkUploadReports;

const InputBox = styled.div`
  border-radius: 5px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid ${COLORS.BORDER_GREY};
  max-width: 450px;
  padding: 9px;
  margin-top: 12px;
  margin-bottom: 14px;
  & .party-listing {
    height: 150px;
    overflow: auto;
  }
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .no-record {
    text-align: center;
    opacity: 0.6;
    padding: 10px;
    width: 100%;
  }
`;

const GirdContainer = styled.div`
  @media ${theme.breakpoints.sm_up} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const ChipContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  max-height: 210px;
  overflow: auto;
  padding: 9px;
  margin-top: 12px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${COLORS.BORDER_GREY};
`;

const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 0px;
  &.party-checkbox {
    padding: 20px 0px;
  }
  & .checkboxlabel {
    color: ${COLORS.COLOR_DARK};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;

const Container = styled.div`
  padding: 14px 43px;
  display: flex;
  flex-direction: column;
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #293461;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width }) => width};
`;

const Loader = styled.div`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
