import React, { createRef, useState, useEffect } from "react";
import styled from "styled-components";
import Header from "./Header";
import Settings from "./Setting";
import MediaPlayer from "./MediaPlayer";
// import TimedTextEditor from "./TimedTextEditor";
import COLORS from "../../../assets/Colors";
import { secondsToTimecode } from "../TimeCodeConverter/secondsToTimecodeNew";
import VideoPlayer from "../PlayerControls/VideoPlayer";
import theme from "../../../assets/theme";
import Export from "./Exports";
import AudioExports from "./AudioExports";
import SlateEditor from "../SlateEditor/index";
import ShortCutKeys from "./ShortCutKeys";

const TranscriptEditor = React.memo(
  ({
    isEditable,
    mediaType,
    mediaUrl,
    data,
    setData,
    caseId,
    handleAutoSaveChanges,
    speakersData,
    meetingId,
    transcriptId,
    title,
    updateState,
    meetingAudioId,
    render,
    setRender,
    captureText,
    name,
    role,
    setFilterSpeaker,
    LiveTranscription,
    isEdited,
    setIsEdited,
    state,
    setState,
    genericLive,
    interimResults,
    endTranscript,
  }) => {
    const [showSettings, setShowSettings] = useState(false);
    const [showShortcuts, setShowShortCuts] = useState(false);
    const [showExportOptions, setShowExportOptions] = useState(false);
    const [isPauseWhileTypingOn, setIsPauseWhileTypingOn] = useState(true);
    const [isScrollIntoViewOn, setIsScrollIntoViewOn] = useState(false);
    const [rollBackValueInSeconds, setRollBackValueInSeconds] = useState(10);
    const [timecodeOffset, setTimecodeOffset] = useState(0);
    const [showTimecodes, setShowTimecodes] = useState(true);
    const [showSpeakers, setShowSpeakers] = useState(true);
    const [previewIsDisplayed, setPreviewIsDisplayed] = useState(true);
    const [mediaDuration, setMediaDuration] = useState("00:00:00");
    const [currentTime, setCurrentTime] = useState(0);
    const transcriptEditorRef = createRef();
    const videoRef = React.useRef();
    const [hookSeek, setHookSeek] = useState();
    const [previousTime, setPreviousTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const onLoadedDataGetDuration = (e) => {
      const currentDuration = e.target.duration;
      const currentDurationWithOffset = currentDuration + timecodeOffset;
      const durationInSeconds = secondsToTimecode(currentDurationWithOffset);

      setMediaDuration(durationInSeconds);
    };

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.currentTime = previousTime;
        if (isPlaying?.isPlaying) {
          setIsPlaying(false);
          videoRef.current.pause();
        } else {
          setIsPlaying(false);
          videoRef.current.pause();
        }
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [render]);

    const handleTimeUpdate = (e) => {
      setPreviousTime(currentTime);
      const currentTimes = videoRef.current.currentTime;
      setCurrentTime(currentTimes);
    };

    const handlePauseWhileTyping = (e) => {
      setIsPauseWhileTypingOn(e?.target?.checked);
    };

    const handleRollBackValueInSeconds = (e) => {
      setRollBackValueInSeconds(e?.target?.value);
    };

    const handleShowTimecodes = (e) => {
      setShowTimecodes(e?.target?.checked);
    };

    const handleShowSpeakers = (e) => {
      setShowSpeakers(e?.target?.checked);
    };

    const handleSetTimecodeOffset = (timecodeOffset) => {
      setTimecodeOffset({ timecodeOffset: timecodeOffset }, () => {
        transcriptEditorRef.current.forceUpdate();
      });
    };

    const handlePreviewIsDisplayed = () => {
      setPreviewIsDisplayed({
        previewIsDisplayed: !this.state.previewIsDisplayed,
      });
    };

    const handleIsScrollIntoViewChange = (e) => {
      setIsScrollIntoViewOn(e?.target?.checked);
    };

    const handleWordClick = (startTime) => {
      setHookSeek(startTime);
    };

    const videoPlayer = (
      <VideoPlayer
        {...{
          mediaUrl,
          previewIsDisplayed,
          onLoadedDataGetDuration,
          videoRef,
        }}
        onTimeUpdate={handleTimeUpdate}
      />
    );

    const settings = (
      <Settings
        {...{
          showSettings,
          setShowSettings,
          isPauseWhileTypingOn,
          isScrollIntoViewOn,
          rollBackValueInSeconds,
          timecodeOffset,
          showTimecodes,
          showSpeakers,
          previewIsDisplayed,
          handlePauseWhileTyping,
          handleIsScrollIntoViewChange,
          handleRollBackValueInSeconds,
          handleSetTimecodeOffset,
          handleShowSpeakers,
          handlePreviewIsDisplayed,
          handleShowTimecodes,
          mediaType,
        }}
      />
    );

    const mediaControls = (
      <MediaPlayer
        {...{
          mediaDuration,
          mediaUrl,
          mediaType,
          currentTime,
          videoRef,
          rollBackValueInSeconds,
          timecodeOffset,
          caseId,
          title,
          setHookSeek,
          isPlaying,
          setIsPlaying,
        }}
        hookSeek={hookSeek}
        videoRef={videoRef}
      />
    );

    const exportOptions = meetingAudioId ? (
      <AudioExports
        {...{
          showExportOptions,
          setShowExportOptions,
          meetingId,
          meetingAudioId,
        }}
      />
    ) : (
      <Export
        {...{
          showExportOptions,
          setShowExportOptions,
          meetingId,
          transcriptId,
        }}
      />
    );

    const shortCutKeys = (
      <ShortCutKeys {...{ showShortcuts, setShowShortCuts }} />
    );

    return (
      <>
        <Container>
          <Header
            {...{
              showSettings,
              showShortcuts,
              showExportOptions,
              setShowSettings,
              setShowShortCuts,
              setShowExportOptions,
              mediaUrl,
            }}
            settings={settings}
            exportOptions={exportOptions}
            shortCutKeys={shortCutKeys}
            mediaControls={videoRef?.current ? mediaControls : null}
          />
          <Grid>
            <Row style={{ display: mediaType === "audio" ? "none" : "" }}>
              <Aside>{videoPlayer}</Aside>
            </Row>
          </Grid>
          {data ? (
            <Main>
              <SlateEditor
                {...{
                  data,
                  setData,
                  mediaUrl,
                  mediaType,
                  timecodeOffset,
                  showTimecodes,
                  currentTime,
                  videoRef,
                  isEditable,
                  showSpeakers,
                  caseId,
                  mediaDuration,
                  handleAutoSaveChanges,
                  speakersData,
                  handleWordClick,
                  updateState,
                  render,
                  setRender,
                  captureText,
                  name,
                  role,
                  isPlaying,
                  setFilterSpeaker,
                  LiveTranscription,
                  isEdited,
                  setIsEdited,
                  state,
                  setState,
                  genericLive,
                  interimResults,
                  endTranscript,
                }}
              />
            </Main>
          ) : null}
        </Container>
      </>
    );
  },
);

export default TranscriptEditor;

const Container = styled.div`
  background-color: ${COLORS.BORDER_GREY};
  position: relative;
  border-radius: 10px;
  // box-shadow: 0 0 10px ${COLORS.INPUT_GRAY};
`;
const Grid = styled.div`
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
`;
const Row = styled.section`
    display: grid;
    /* grid-template-columns: 1fr 3fr; */
    grid - column - gap: 1em;
`;
const Aside = styled.aside`
  margin-left: auto;
`;
const Main = styled.section`
  background-color: ${COLORS.PRIMARY_WHITE};
  position: relative;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-radius: 10px;
  height: 438px;
  // border-color: ${COLORS.LOGOUT_RED};
  font-family: ${theme.fonts.primaryFontSemiBold};
  box-shadow: 0 0 5px ${COLORS.BTN_GREEN};
  @media (min-width: 1024px) {
    height: 405px;
  }
  @media (min-width: 1280px) {
    height: 386px;
  }
  @media (min-width: 1366px) {
    height: 436px;
  }
`;
