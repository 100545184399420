import React from "react";
import { Router } from "@reach/router";
import ManagerLogin from "./components/pages/ManagerLogin";
import DashboardScreens from "./components/pages/DashboardScreens";
import MyTasks from "./components/pages/MyTasks";
import PendingActions from "./components/internal/PendingActions";
import Uploads from "./components/internal/Uploads";
import VerifyDetails from "./components/internal/VerifyDetails";
import LoginPath from "./components/pages/ManagerLogin/LoginPath";
import PartyDetails from "./components/internal/PartyDetails";
import Party from "./components/pages/TemporatyRoute";
import Profiles from "./components/pages/TemporatyRoute";
import VerifyParties from "./components/pages/TemporatyRoute";
import NegotiationListing from "./components/pages/TemporatyRoute";
import MediationListing from "./components/pages/TemporatyRoute";
import ArbitrationListing from "./components/pages/TemporatyRoute";
import MyCases from "./components/internal/MyCases";
import MyProfiles from "./components/internal/MyProfiles";
import CaseDetails from "./components/pages/CaseDetails";
// import Configurations from "./components/pages/Configurations";
// import MyConfigurations from "./components/internal/MyConfigurations";
// import UpdateTerminologies from "./components/internal/UpdateTerminologies";
// import UpdateFAQs from "./components/pages/UpdateFAQs";
import ProfileDetails from "./components/pages/ProfileDetails";
import BulkUploads from "./components/pages/BulkUploads";
import labels from "./helpers/labels.json";
import Parties from "./components/internal/MyParties";
import MailRedirection from "./components/pages/MailRedirection";
import ProformaInvoiceListing from "./components/internal/ProformaInvoiceListing";
import PaymentsPage from "./components/pages/PaymentsPage/index";
import PaymentHistoryPage from "./components/internal/PaymentHistoryPage";
import GenericMeeting from "./components/pages/Recordings/index";
import RecordingsPage from "./components/internal/RecordingsPage/index";
import GuestGenericMeeting from "./components/pages/JoinGenericMeeting";
import TimeSlideTranscription from "./components/pages/TimeSlideTranscription/index";
import RecordingsTimeSlide from "./components/pages/RecordingsTimeSlide";
import LiveTranscription from "./components/internal/TimeSlideTranscript/LiveTranscription";
import Transcriber from "./components/pages/Transcriber/index";
import TranscriberTable from "./components/internal/TranscriberTable";
import ViewTranscriber from "./components/internal/ViewTranscriber/index";
import CreateTranscriber from "./components/internal/CreateTranscriber/index";
import EditTranscriber from "./components/internal/EditTranscriber/EditTranscriber";
import ChangePassword from "./components/pages/ChangePassword/index";
import AudioTranscription from "./components/pages/TimeSlideTranscription/AudioTranscription";
import MeetingDetailsRoute from "./components/pages/Recordings/index";
import MeetingDetails from "./components/pages/MeetingDetails/index";
import SessionManagement from "./components/pages/SessionManagement";
import CrossExamination from "./components/internal/CrossExamination/index";
import CrossExaminationTranscript from "./components/internal/CrossExamination/CrossExaminationTranscripts";
// import NeutralView from "./components/pages/NeutralView/index";
// import NeutralViewTable from "./components/internal/NeutralViewTable/index";
// import CreateNeutral from "./components/internal/CreateNeutral/index";
// import EditNeutral from "./components/internal/EditNeutral/index";
// import ViewNeutral from "./components/internal/ViewNeutral";
import ViewOnlyUser from "./components/internal/ViewOnlyUser/index";
import CreateViewOnlyUser from "./components/internal/ViewOnlyUser/CreateViewOnlyUser";
import UpdateViewOnlyUser from "./components/internal/ViewOnlyUser/UpdateViewOnlyUser";
import BulkNotice from "./components/internal/NoticeGeneration/index";
// import DailyCause from "./components/internal/DailyCause/index";
import DailyCause from "./components/calender/DailyCause";
import MisRoute from "./components/pages/MisRoute/index";
import MisDashboard from "./components/Mis";
import ViewCases from "./components/ViewCases";
import BulkUploadRename from "./components/internal/BulkUploadRename";

/* Bulk upload reports */
import BulkUploadReports from "./components/internal/BulkUploadReports/index.js";

const AppRouter = () => {
  return (
    <Router>
      <LoginPath path="/">
        <ManagerLogin path="/" />
      </LoginPath>
      <DashboardScreens path="/dashboard">
        <ChangePassword path="/change-password" />
        <MyTasks path="/">
          <PendingActions path="/" />
          <ProformaInvoiceListing path="/proforma-invoice" />
          <Uploads path="/uploads" />
          <VerifyParties
            selectedItem={labels.my_tasks}
            selectedSubItem={labels.verify}
            path="/verify-parties"
          >
            <VerifyDetails path="/" />
            <PartyDetails path="/:partyId" />
          </VerifyParties>
          <BulkNotice path="/bulk-notice" />
          <BulkUploadReports path="/document-reports" />
        </MyTasks>
        <NegotiationListing
          path="/negotiation"
          selectedSubItem={labels.negotiation}
          selectedItem={labels.cases}
        >
          <MyCases path="/" type="negotiation" />
          <CaseDetails path="/:id" />
        </NegotiationListing>
        <MediationListing
          path="/mediation"
          selectedSubItem={labels.mediation}
          selectedItem={labels.cases}
        >
          <MyCases path="/" type="mediation" />
          <CaseDetails path="/:id" />
          <TimeSlideTranscription path="/:id/transcription" type="mediation" />
          <AudioTranscription
            path="/:id/split-transcription"
            type="mediation"
          />
          <LiveTranscription path="/:id/livetranscription" type="mediation" />
          <CrossExamination path="/:id/cross-examination" type="mediation" />
          <CrossExaminationTranscript
            path="/:id/cross-examination-transcript"
            type="mediation"
          />
        </MediationListing>
        <ArbitrationListing
          path="/arbitration"
          selectedSubItem={labels.arbitration}
          selectedItem={labels.cases}
        >
          <MyCases path="/" type="arbitration" />
          <CaseDetails path="/:id" />
          <ViewCases path="/view/:id" />
          <TimeSlideTranscription
            path="/:id/transcription"
            type="arbitration"
          />
          <AudioTranscription
            path="/:id/split-transcription"
            type="arbitration"
          />
          <LiveTranscription path="/:id/livetranscription" type="arbitration" />
          <CrossExamination path="/:id/cross-examination" type="arbitration" />
          <CrossExaminationTranscript
            path="/:id/cross-examination-transcript"
            type="arbitration"
          />
        </ArbitrationListing>
        <Party selectedItem={labels.parties} path="/parties">
          <Parties path="/" />
          <PartyDetails path="/:partyId" isFromListing={true} />
        </Party>
        <Profiles selectedItem={labels.profiles} path="/profiles">
          <MyProfiles path="/" />
          <ProfileDetails path="/:userId" />
          <CreateViewOnlyUser path="/create-view-only-user" />
          <ViewOnlyUser path="/view-only-user/:id" />
          <UpdateViewOnlyUser path="/edit/:id" />
        </Profiles>
        <BulkUploads path="/bulk-uploads" />
        <PaymentsPage path="/payments">
          <PaymentHistoryPage path="/" />
        </PaymentsPage>
        <GenericMeeting
          selectedSubItem={labels.generic_meeting}
          selectedItem={labels.meetings}
          path="/generic-meeting"
        >
          <RecordingsPage path="/" />
          <RecordingsTimeSlide path="/transcription" />
        </GenericMeeting>
        <MeetingDetailsRoute
          selectedSubItem={labels.meeting_details}
          selectedItem={labels.meetings}
          path="/meeting-details"
        >
          <MeetingDetails path="/" />
          <SessionManagement path="/session-management" />
        </MeetingDetailsRoute>
        <MeetingDetailsRoute
          selectedSubItem={labels.daily_casuse}
          selectedItem={labels.meetings}
          path="/daily-cause"
        >
          <DailyCause path="/" />
        </MeetingDetailsRoute>
        <Transcriber selectedItem={labels.transcriber} path="/transcriber">
          <TranscriberTable path="/" />
          <CreateTranscriber path="/create-transcriber" />
          <EditTranscriber path="/edit/:id" />
          <ViewTranscriber path="/:id" />
        </Transcriber>
        <MisRoute selectedItem={labels.mis_dashboard} path="/mis-dashboard">
          <MisDashboard path="/" />
        </MisRoute>
        <MisRoute selectedItem={labels.management_tools} path="/tools">
          <BulkUploadRename path="/" />
        </MisRoute>
        {/* <NeutralView selectedItem={labels.neutral} path="/neutral">
          <NeutralViewTable path="/" />
          <CreateNeutral path="/create-neutral" />
          <EditNeutral path="/edit/:id" />
          <ViewNeutral path="/:id" />
        </NeutralView> */}
        <MailRedirection path="/cases/:id" redirectTo="case" />
      </DashboardScreens>
      <GuestGenericMeeting path="/joinMeeting/:code" />
    </Router>
  );
};

export default AppRouter;
