import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ReactMic } from "react-mic";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import axios from "axios";
import { Tooltip } from "@material-ui/core";

const Index = ({ setComment, isLocalTranscriptionServer }) => {
  const [transcribedData, setTranscribedData] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const transcribeTimeout = 5;
  const [stopTranscriptionSession, setStopTranscriptionSession] =
    useState(false);

  const stopTranscriptionSessionRef = useRef(stopTranscriptionSession);
  stopTranscriptionSessionRef.current = stopTranscriptionSession;
  const intervalRef = useRef(null);

  useEffect(() => {
    if (transcribedData) {
      const convertToString = transcribedData.join("");
      const sentences = convertToString.split(/(?<=[.?!])\s+/);
      // capitalize the first letter after a dot in each sentence
      const capitalizedSentences = sentences.map((sentence) => {
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
      });
      setComment(capitalizedSentences.join(" "));
    }
  }, [transcribedData]); // eslint-disable-line react-hooks/exhaustive-deps

  function startRecording() {
    setStopTranscriptionSession(false);
    setIsRecording(true);
    intervalRef.current = setInterval(
      transcribeInterim,
      transcribeTimeout * 1000,
    );
  }

  function stopRecording() {
    clearInterval(intervalRef.current);
    setStopTranscriptionSession(true);
    setIsRecording(false);
    setIsTranscribing(false);
  }

  function transcribeInterim() {
    clearInterval(intervalRef.current);
    setIsRecording(false);
  }

  function onData(recordedBlob) {
    // console.log('chunk of real-time data is: ', recordedBlob);
  }

  function onStop(recordedBlob) {
    transcribeRecording(recordedBlob);
    setIsTranscribing(true);
  }

  const transcribeRecording = async (recordedBlob) => {
    const headers = {
      "content-type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("language", "english");
    formData.append("isLocalTranscriptionServer", isLocalTranscriptionServer);
    formData.append("model_size", "base");
    formData.append("audio_data", recordedBlob.blob, "temp_recording");
    axios
      .post(
        isLocalTranscriptionServer === 1
          ? `https://transcription-api.justact.co.in/orderDictate`
          : isLocalTranscriptionServer === 2
          ? `https://transcription.justact.co.in/orderDictate`
          : `https://transcription-api.justact.co.in/orderDictate`,
        formData,
        {
          headers,
        },
      )
      .then((res) => {
        setTranscribedData((oldData) => [...oldData, res.data]);
        setIsTranscribing(false);
        intervalRef.current = setInterval(
          transcribeInterim,
          transcribeTimeout * 1000,
        );
      });
    if (!stopTranscriptionSessionRef.current) {
      setIsRecording(true);
    }
  };

  return (
    <Container>
      {!isRecording && !isTranscribing && (
        <MicIconStart
          onClick={startRecording}
          style={{
            color: COLORS.COLOR_DARK,
            fontFamily: theme.fonts.primaryFontBold,
          }}
        >
          <Tooltip title="Start Dictation" placement="left">
            <ProfileImage
              src={require("../../../assets/images/JustAct Icons for Case Manager Site_V4-02.svg")}
            />
          </Tooltip>
        </MicIconStart>
      )}
      {/* {(isRecording || isTranscribing) && (
        <MicIconReset
          onClick={() => setTranscribedData([])}
          style={{
            color: COLORS.COLOR_DARK,
            fontFamily: theme.fonts.primaryFontBold,
          }}
        >
          <Tooltip title="Reset" placement="left">
            <ProfileImage
              src={require("../../../assets/images/JustAct Icon ReDesign_V1-01.png")}
            />
          </Tooltip>
        </MicIconReset>
      )} */}
      {(isRecording || isTranscribing) && (
        <MicIconStop
          onClick={stopRecording}
          disabled={stopTranscriptionSessionRef.current}
          style={{
            color: COLORS.COLOR_DARK,
            fontFamily: theme.fonts.primaryFontBold,
          }}
        >
          <Tooltip title="Stop Dictation" placement="left">
            <ProfileImage
              src={require("../../../assets/images/JustAct Icons for Case Manager Site_V4-03.svg")}
            />
          </Tooltip>
        </MicIconStop>
      )}
      <MicIconReset
        onClick={() => setTranscribedData([])}
        style={{
          color: COLORS.COLOR_DARK,
          fontFamily: theme.fonts.primaryFontBold,
        }}
      >
        <Tooltip title="Reset" placement="right">
          <ProfileImage
            src={require("../../../assets/images/JustAct Icon ReDesign_V1-01.png")}
          />
        </Tooltip>
      </MicIconReset>
      <ReactMic
        record={isRecording}
        className="react-mic"
        onStop={onStop}
        onData={onData}
        // height={50}
        strokeColor="#00838c"
        backgroundColor="#f6f6ef"
        visualSetting="none"
      />
    </Container>
  );
};

export default Index;

const MicIconStart = styled.div`
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 32px;
`;

const MicIconStop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 32px;
`;

const MicIconReset = styled.div`
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  // margin-left: 30px;
`;

const ProfileImage = styled.img`
  width: 42px;
  height: 35px;
  border-radius: 50%;
  object-fit: contain;
`;

const Container = styled.div`
  display: flex;
  .react-mic {
    width: 100%;
  }
`;
